h2 {
    text-align: center;
}

.icons {
    margin: 0 10px 0 0;
}

.tech {
    /* margin: 0 auto; */
}