@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

body {
    background-color: #1d1d1d;
    margin: 0;
}

.container {
    font-family: Uni ui-sans-serif;
}

h1 {
    margin-bottom: 0;
}

.grey-box {
    box-sizing: border-box;
    border-radius: 5px;
    margin: 5% 0 0 12%;
    padding: 20px;
    background-color: #2c2f33;
    width: 80%;
}

.mobile-about {
    margin-top: 10em;
}

.sections-container {
    padding-top: 60px; /* Adjust the value based on your navbar height */
}

@media only screen and (min-width: 1070px) {
    .grey-box {
        margin: 2% 0 0 12%;
        width: 80%;
    }

    .sections-container {
        padding-top: 0; /* Adjust the value based on your navbar height */
    }

}