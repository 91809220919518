.background-image {
    opacity: 0.5;
    background-size: cover;
}

.background-text {
    opacity: 1;
}

@media only screen and (min-width: 1070px) {
    .background-text {
        position: absolute;
        top: 205px;
        left: 470px;
        color: white;
    }
}
@media only screen and (max-width: 400px) {
    .background-text {
        position: absolute;
        top: 305px;
        color: white;
    }

    .about {
        height: 10vh;
    }

    .background-image {
        height: 100vh;
    }
}
