@media only screen and (min-width: 1090px) {
    body {
        font-size: 1.6rem;
        line-height: 1.5;
        display: block;
        min-width: 320px;
        padding-left: 13rem;
        overflow-y: scroll;
        overflow-x: hidden;
        background: #1d1d1d;
    }

    .navlinks {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #282828;
    }
    
    .navlinks a {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #282828;
        line-height: 5rem;
        display: block;
    }
}

.sidenav {
    height: 100vh;
    width: 20rem;
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    background-color: #181818;
    overflow: hidden;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.menu-top h4 {
    margin: 15px 0 0 0;
}

.menu-top p {
    font-size: 15px;
    margin: 0;
}

a:hover {
    color: coral;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10rem;
    height: auto;
    border-radius: 50%;
}

.menu-top {
    background: #000000;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
}

.socials {
    color: #909096;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    height: 20rem;
}

.socials li {
    text-align: center;
}

.socials li a {
    padding: 2rem 7px;
}