div {
    color: white;
}

h1 {
    text-align: center;
}

.icons {
    height: 36px;
}

.image-div a {
    display: flex;
    align-items: center;
    justify-content: center;  
}

.image-div {
    display: flex;
    align-items: center;
    justify-content: center;
}

#image {
    width: 50%;
    border-radius: 5px;
}

.tech-stack {
    /* margin: 0 0 16px 28px; */
    text-align: center;
}

/* .tech-stack img:nth-child(-n+3) {
    margin-bottom: 0.5em;
} */

/* @media only screen and (max-width: 1080px) {
    .project-container {
        margin: 0 auto;
    }
} */

@media only screen and (max-width: 400px) {
    #image {
        width: 100%;
        border-radius: 5px;
    }
}