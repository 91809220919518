.navlinks-mobile {
    display: flex;
}

.navlinks-mobile a {
    text-align: center;
    display: block;
    color: white;
}

.navbar {
    overflow: hidden;
    background-color: #333;
    position: fixed; /* Set the navbar to fixed position */
    width: 100%;
    top: 0; /* Position the navbar at the top of the page */
    z-index: 1;
}

.right-side {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

